<template>
    <div>
        <div style='position: relative'>
            <div class="restriction-dialog" v-if='restricted'>
                <div class="warn-sign"><img src="../../../../../../../../static/img/exclamation.svg" alt=""></div>
                <p>Your current plan does not include Popover video embed.</p>
                <p>Please upgrade to one of our premium plans to get access to this feature.</p>
                <el-button round class="restriction_dialog_btn" type="success" @click="$emit('upgrade')"> See Upgrade
                    Options
                </el-button>
            </div>
            <div class="popover-embed-code">
                <a href="#" target="_blank" @click="openEdtior">Go to Privacy Settings</a>
                <div class="popover-embed-head d-flex justify-content-between">
                    <p class="title-small">Embed Code</p>
                    <button class="btn" @click='copyToClipboard'>Copy</button>
                </div>
                <textarea ref='embedCode' v-model='embedUrlLink'></textarea>
            </div>
            <div class="popover-embed-options">
                <div class='options-section d-flex justify-content-between'>
                    <div class='size-options'>
                        <p class='title'>Options</p>
                        <div class="thumbnail-size">
                            <div class="responsive-size d-flex size-options justify-content-between">
                                <div :class="option == 'thumbnail' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="option = 'thumbnail'">
                                    <div :class="option == 'thumbnail' ? 'icon-inner-selected' : ''"></div>
                                </div>
                                <div class="label">Thumbnail</div>
                            </div>
                            <div class="size-inputs d-flex">
                                <input type="number" class='width dimensions' v-model='width'>
                                <div @click='aspectRatio' class="lock-icon" :class='lockedAspectRatio ? "locked" : "unlocked"'>
                                    <svg id="Action_Expand" data-name="Action Expand" xmlns="http://www.w3.org/2000/svg" width="10.454" height="12.545" viewBox="0 0 10.454 12.545"><path id="Path_9388" data-name="Path 9388" d="M14.409,9H6.045A1.045,1.045,0,0,0,5,10.045v5.576a1.045,1.045,0,0,0,1.045,1.045h8.363a1.045,1.045,0,0,0,1.045-1.045V10.045A1.045,1.045,0,0,0,14.409,9Zm.348,6.621a.348.348,0,0,1-.348.348H6.045a.348.348,0,0,1-.348-.348V10.045A.348.348,0,0,1,6.045,9.7h8.363a.348.348,0,0,1,.348.348Z" transform="translate(-5 -4.121)" fill="#21455e"/><path id="Path_9389" data-name="Path 9389" d="M11.136,2A3.143,3.143,0,0,0,8,5.136V7.227a.348.348,0,0,0,.348.348h5.576a.348.348,0,0,0,.348-.348V5.136A3.143,3.143,0,0,0,11.136,2ZM8.7,6.879V5.136a2.439,2.439,0,1,1,4.879,0V6.879Z" transform="translate(-5.909 -2)" fill="#21455e"/><rect id="Rectangle_3246" data-name="Rectangle 3246" width="1" height="3" transform="translate(5 7.032)" fill="#21455e"/></svg>
                                </div>
                                <input type="number" class='height dimensions' v-model='height'>
                            </div>
                            <div class="responsive-switch d-flex">
                                <svg @click='responsive = false' v-if='responsive' id="Checked" xmlns="http://www.w3.org/2000/svg" width="17.371" height="17.371" viewBox="0 0 17.371 17.371"><path id="Rectangle-4-Copy-6" d="M24.343,152.5h8.686a4.343,4.343,0,0,1,4.343,4.343v8.686a4.343,4.343,0,0,1-4.343,4.343H24.343A4.343,4.343,0,0,1,20,165.528v-8.686A4.343,4.343,0,0,1,24.343,152.5Z" transform="translate(-20 -152.5)" fill="#21455e" fill-rule="evenodd"/><path id="Path_8230" data-name="Path 8230" d="M8.951,68.131a.454.454,0,0,0-.642,0L2.867,73.573.775,71.481a.454.454,0,0,0-.642.642l2.413,2.413a.454.454,0,0,0,.642,0l5.763-5.763A.454.454,0,0,0,8.951,68.131Z" transform="translate(4.001 -62.764)" fill="#fff" stroke="#fff" stroke-width="0.2"/></svg>
                                <div @click='responsive = true' v-else class="unchecked"></div>
                                <p class="title-small">Make Responsive</p>
                                <div class='info-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.529" height="12.529" viewBox="0 0 12.529 12.529"><path id="Path_9537" data-name="Path 9537" d="M11.265,5a6.265,6.265,0,1,0,6.265,6.265A6.265,6.265,0,0,0,11.265,5Zm.783,9.237a.783.783,0,1,1-1.566,0V10.716a.783.783,0,1,1,1.566,0Zm-.783-5.162a.783.783,0,1,1,.783-.783.783.783,0,0,1-.783.783Z" transform="translate(-5 -5)" fill="#21455e" opacity="0.7"/></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="thumbnail-preview">
                        <p class='title'>Preview</p>
                        <div v-if='option == "thumbnail"' class='thumbnail-image'>
                            <div class="play-video-icon">
                                <i class="fa fa-play"></i>
                            </div>
                            <img :src="thumbnail_url">
                        </div>
                        <div style="text-align: center" v-else>{{ textLink }}</div>
                    </div>
                </div>
                <div class="thumbnail-size">
                    <div class="responsive-size d-flex size-options justify-content-between">
                        <div :class="option == 'text' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="option = 'text'">
                            <div :class="option == 'text' ? 'icon-inner-selected' : ''"></div>
                        </div>
                        <div class="label">Text Link</div>
                    </div>
                    <div class="text-link">
                        <input @change='getLink' v-model='textLink' type="text" placeholder="Click to view video">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="stylus" scoped>
    .popover-embed-head {
        padding: 10px 0;
        width: 469px;
    }

    .popover-embed-head .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        opacity: 1;
        color: #FFFFFF;
    }

    .title-small {
        margin-top: 6px;
        font-family: Helvetica Neue;
        font-size: 15px;
        letter-spacing: 0px;
        color: #21455E;
        text-transform: capitalize;
        opacity: 1;
    }

    .popover-embed-code textarea {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E2E5ED;
        border-radius: 6px;
        opacity: 1;
        width: 469px;
        height: 134px;
        padding: 15px 15px;
        margin-top: 15px;
        color: #98A4B3;
        resize: none;
    }

    .title {
        text-align: left;
        font-family: Helvetica Neue;
        font-size: 19px;
        color: #21455E;
        opacity: 1;
    }

    .popover-embed-options {
        padding: 15px 0;
        width: 469px;
    }

    .icon-outer-selected {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 2px solid #21455E;
    }

    .icon-inner-selected {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: #21455E 0% 0% no-repeat padding-box;
        position: relative;
        left: 0;
        top: 2px;
        right: 0;
        margin: auto;
    }

    .icon-outer-unselected {
        border: 2px solid #D8DCE6;
        border-radius: 8px;
        width: 16px;
        height: 16px;
    }

    .unselected {
        border: 2px solid #D8DCE6;
    }

    .thumbnail-size {
        width: 100px;
    }

    .size-inputs .dimensions {
        width: 70px;
        height: 38px;
        border: 1px solid #E2E5ED;
        margin-top: 20px;
    }

    .size-inputs .width {
        border-radius: 5px 0px 0px 5px;
        margin-left: 40px;
        text-align: center;
    }

    .size-inputs .height {
        border-radius: 0px 5px 5px 0px;
        text-align: center;
    }

    .lock-icon {
        width: 25px;
        height: 38px;
        margin-top: 20px;
        padding: 8px;
        cursor: pointer;
    }

    .locked { background: rgba(0, 172, 220, 0.178); }
    .unlocked { background: #F5F6F9; }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .unchecked {
        width: 17px;
        height: 16px;
        border: 2px solid #D8DCE6;
        border-radius: 3px;
    }

    .responsive-switch {
        margin-left: 40px;
        margin-top: 20px;
        width: 200px;
    }

    .responsive-switch svg {
        margin-top: 8px;
    }

    .responsive-switch .unchecked {
        margin-top: 8px;
    }

    .responsive-switch .title-small {
        margin-left: 15px;
    }

    .info-icon {
        margin-left: 15px;
        margin-top: 3px;
    }
    .text-link { margin-left: 40px; }
    .text-link input {
        width: 165px;
        height: 38px;
        border: 1px solid #E2E5ED;
        margin-top: 10px;
        border-radius: 5px;
        text-align: center;
    }

    input::placeholder {
        padding: 20px;
        color: #98A4B3;
    }

    .thumbnail-preview {
        padding: 0 60px;
    }

    .thumbnail-preview img {
        width: 150px;
        height: 80px;
    }
    
    .thumbnail-image svg {
        width: 42px;
        position: absolute;
        transform: translateX(120%) translateY(-2%);
    }

    .play-video-icon {
        z-index: 2;
        position: absolute;
        background: rgba(0, 172, 220, 0.9);
        box-shadow: 0px 3px 6px #00000029;
        color: #B6B9BB;
        border-radius: 50%;
        text-align: center;
        width: 42px;
        margin: auto;
        height: 42px;
        transform: translateX(120%) translateY(45%);
    }
    .play-video-icon i {
        font-size: 20px !important;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 12px;
        margin-left: 5px;
    }

    ::-webkit-scrollbar { width: 20px; }
    ::-webkit-scrollbar-track { background: rgb(255, 255, 255); }
    ::-webkit-scrollbar-thumb {
        background: #C9EDF8;
        background-clip: padding-box;
        border: 15px solid rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb:hover { background: #C9EDF8; }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

    .restriction-dialog {
        font-size: 14px;
        width: 100%;
        display: flex;
        background: rgba(1, 26, 34, 0.822);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 95;
        border-radius: 12px;
        backdrop-filter: blur(8px);
        p {
            padding: 2px;
            color: #fff;
        }
    }

    .warn-sign {
        position: relative;
        width: 60px;
        height: 60px;
        text-align: center;
        margin-bottom: 15px;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }



</style>

<script>
export default {
    props: ['videoData', 'restricted'],
    data: () => ({
        lockedAspectRatio: true,
        width: 853,
        height: 480,
        responsive: false,
        option: 'thumbnail',
        embedUrlLink: '',
        embedUrl: '',
        thumbnail_url: '',
        popover: '',
        videoId: '',
        textLink: ''
    }),
    mounted()
    {
        let videoData = this.$props.videoData,
            thumbnail = '',
            thumbnails = [];
        if (this.$props.restricted) return false;
        if (videoData) {
            if (videoData.videos) {
                thumbnails = [videoData.videos.a.thumbnail, videoData.videos.b.thumbnail],
                thumbnail = thumbnails[Math.floor(Math.random())];
            } else {
                thumbnail = videoData.thumbnail_url;
            }
            this.embedUrl = videoData.url;
            this.thumbnail_url = thumbnail;
            this.videoId = videoData.video_id;
        }
        this.getLink();
        this.popover = `${process.env.VUE_APP_BACKEND_URL}js/popover.js`;
    },
    watch:
    {
        width(n, o) {
            this.height = this.lockedAspectRatio ? parseInt(n) - 100 : this.height;
            this.getLink()
        },
        height(n, o) {
            this.width = this.lockedAspectRatio ? parseInt(n) + 100 : this.width;
            this.getLink()
        },
        responsive()
        {
            this.getLink();
        },
        option(n, p)
        {
            this.getLink();
        }
    },
    methods: {
        aspectRatio()
        {
            this.lockedAspectRatio = this.lockedAspectRatio ? false : true;
            this.height = this.width - 101;
        },
        copyToClipboard()
        {
            let element = this.$refs.embedCode;
            if (element) {
                element.select();
                document.execCommand('copy');
                this.$emit('copySuccess', true);
            }
        },
        getLink()
        {
            if (this.$props.restricted) return false;
            this.embedUrlLink = '';
            let responsive = this.responsive;
            let domain = process.env.VUE_APP_BACKEND_URL.includes('bigcommand.com') ? 'https://cdn.bigcommand.com/' : this.backendUrl;
            let url        = `${domain}dynamic-embed/js/popover.js`;
            let textLink   = this.option == 'text' ? this.textLink : '';
            let experiment = !isNaN(this.videoId) ? true : false;
            let link = `&lt;script type="text/javascript" src="${url}" async=""&gt;&lt;/script&gt;<div class="motion_popover" data-width='${responsive ? "100%" : this.width}' data-height='${responsive ? "100%" : this.height}' data-id='${this.videoId}' data-type='${this.option}' data-text='${textLink}' data-experiment='${experiment}'></div>`;
            link = link.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
            this.embedUrlLink = link;
        },
        openEdtior(e)
        {
            e.preventDefault();
            let routeData = this.$router.resolve({name: 'VideoEditor', params: {
                projectId: this.$route.params.id,
                id: this.$props.videoData.video_id_string
            },
            query: {
                s: true
            }});
            window.open(routeData.href, '_blank');

        }
    }
}
</script>
