<template>
    <div class="metadata-modal">
        <div class="metadata-modal-content">
            <div class="metadata-modal-body">
                <div class="metadata-modal-header d-flex justify-content-between">
                    <div class="metadata-modal-back" @click='back'><svg xmlns="http://www.w3.org/2000/svg" width="22.33" height="13.987" viewBox="0 0 22.33 13.987"><path id="Path_4762" data-name="Path 4762" d="M21.029,81.781H3.248l4.243-4.243a.951.951,0,0,0-1.345-1.345L.279,82.06a.951.951,0,0,0,0,1.345l5.867,5.867a.951.951,0,0,0,1.345-1.345L3.248,83.684h17.78a.951.951,0,1,0,0-1.9Z" transform="translate(0.175 -75.739)" fill="#a3bac6" stroke="#fff" stroke-width="0.35"/></svg></div>
                    <div class="metadata-modal-title">VIDEO METADATA</div>
                    <div class="metadata-modal-close" @click='close'><i v-if='!summaryPage' class="fas fa-times"></i></div>
                </div>
                <div v-if='metadata.a' class="video-buttons d-flex justify-content-between">
                    <button class="btn" @click="currentVideo = 'a'">Video A</button>
                    <button class="btn" @click="currentVideo = 'b'">Video B</button>
                </div>
                <div class="metadata-table d-flex">
                    <div class="metadata-attributes">
                        <div class='attribute'>Title:</div>
                        <div class='attribute'>Description:</div>
                        <div class='attribute'>Embed URL:</div>
                        <div class='attribute'>Thumbnail URL:</div>
                        <div class='attribute'>Duration:</div>
                    </div>
                    <div class="metadata-values">
                        <div :title='all_data[currentVideo].title' class='value'>{{ all_data[currentVideo].title }}</div>
                        <div :title='all_data[currentVideo].description' class='value'>
                            <div v-if='all_data[currentVideo].description'>
                                {{ all_data[currentVideo].description }}
                            </div>
                            <div v-else class='no-description d-flex'>
                                <div>
                                    <svg id="Group_10998" data-name="Group 10998" xmlns="http://www.w3.org/2000/svg" width="18.088" height="18.088" viewBox="0 0 18.088 18.088"><circle id="Ellipse_4260" data-name="Ellipse 4260" cx="9.044" cy="9.044" r="9.044" fill="#21455e"/><g id="Group_8569" data-name="Group 8569" transform="translate(10.103 13.813) rotate(180)"><circle id="Ellipse_4259" data-name="Ellipse 4259" cx="0.994" cy="0.994" r="0.994" fill="#fff"/><path id="Path_8229" data-name="Path 8229" d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z" transform="translate(0.435 3.61)" fill="#fff"/></g></svg>
                                    This video has no description
                                </div>
                                <a href="#" class='add-desc' @click="showDescriptionModal = true">Add Description</a>
                            </div>
                        </div>
                        <div :title='all_data[currentVideo].embed_url' class='value'>
                            <a :href="all_data[currentVideo].embed_url">{{ all_data[currentVideo].embed_url }}</a>
                        </div>
                        <div :title='all_data[currentVideo].thumbnail_url' class='value'>
                            <a :href="all_data[currentVideo].thumbnail_url">{{ all_data[currentVideo].thumbnail_url }}</a>
                        </div>
                        <div class='value'>{{ all_data[currentVideo].duration }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if='showDescriptionModal'>
            <EmbedDescriptionModal @close='showDescriptionModal = false' @description='description' :video='all_data[currentVideo]'></EmbedDescriptionModal>
        </div>
    </div>
</template>

<style lang="stylus" scoped>
    .metadata-modal {
        position: fixed;
        z-index: 2002;
        width: 100vw;
        height: 100vw;
        top: 0;
        left: 0;
        background: rgba(1, 26, 34, 0.301);
    }

    .metadata-modal-content {
        position: relative;
        left: 50%;
        top: 10%;
        transform: translateX(-50%) translateY(-10%);
        width: 637px;
        height: 395px;
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #00000014;
        border: 1px solid #0BACDB;
        border-radius: 12px;
        opacity: 1;
    }
    .metadata-modal-close {
        cursor: pointer;
        position: relative;
        right: 20px;
    }

    .metadata-modal-back{
        cursor: pointer;
        position: relative;
        left: 15px;
    }

    .metadata-modal-close i {
        color: #A3BAC6;
    }

    .metadata-modal-header {
        padding: 15px;
        border-bottom: 1px solid #00000014;
    }

    .no-description {
        width: 244px;
        padding: 0 20px;
        font-size: 13px;
        color: #A3BAC6;
    }

    .no-description svg {
        position: relative;
        top: 4px;
    }

    .metadata-attributes { padding: 25px 25px; }
    .metadata-attributes .attribute { padding: 20px 0; }
    .metadata-values { padding: 25px 25px; }
    .metadata-values .value {
        padding: 20px 0;
        width: 400px;
        height: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .video-buttons {
        padding: 5px 20px;
        position: absolute;
        width: 100%;
    }

    .video-buttons .btn {
        background: transparent;
    }

    .add-desc {
        position: absolute;
        right: 40px;
        line-height: 2;
        color: #019EDB;
    }

</style>

<script>
import EmbedDescriptionModal from './EmbedDescriptionModal';
export default {
    props: ['metadata', 'summaryPage'],
    data: () => ({
        all_data: null,
        currentVideo: 'a',
        showDescriptionModal: false
    }),
    components: {
        EmbedDescriptionModal
    },
    methods: {
        close()
        {
            this.$emit('closeMainModal', true);
        },
        back()
        {
            this.$emit('trigger', false);
        },
        description(data)
        {
            this.all_data[this.currentVideo].description = data.description;
        }
    },
    created()
    {
        let metadata = this.$props.metadata;
        if (metadata) {
            // If type = videos
            if (metadata.a) {
                this.all_data = {
                    a: metadata.a,
                    b: metadata.b
                };
            } else {
                this.all_data = {
                    a: metadata
                }
            }
        }
    }
}
</script>