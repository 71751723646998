<template>
    <div>
        <div style='position: relative'>
            <div class="restriction-dialog" v-if='restricted'>
                <div class="warn-sign"><img src="../../../../../../../../static/img/exclamation.svg" alt=""></div>
                <p>Your current plan does not include Email video embed.</p>
                <p>Please upgrade to one of our premium plans to get access to this feature.</p>
                <el-button round class="restriction_dialog_btn" type="success" @click="$emit('upgrade')"> See Upgrade
                    Options
                </el-button>
            </div>
            <div class="email-embed-code">
                <div class="email-embed-head d-flex justify-content-between">
                    <p class="title-small">Embed Code</p>
                    <button v-if='!loader' class="btn" @click='copyToClipboard'>Copy</button>
                    <div v-else class='btn'><Loader :centered='true' :lightTheme='true'></Loader></div>
                </div>
                <textarea ref='embedCode' v-model='embedUrlLink'></textarea>            
            </div>
            <div class="email-embed-options">
                <div class='options-section'>
                    <div class='size-options'>
                        <p class='title'>Options</p>
                    </div>
                </div>
                <div class="video_animation_toggle d-flex">
                    <div class="animation-toggle-button" @click='video_animation = !video_animation'>
<!--                        <PerformanceGoalsToggle-->
<!--                            :toggle='video_animation'-->
<!--                            :type='"video_animation"'-->
<!--                        ></PerformanceGoalsToggle>-->
                    </div>
                    <div class="animation-toggle-text">
                        Play Video Animation in Email
                    </div>
                </div>
                <div class="video-timer-section d-flex">
                    <div class="video-timer-labels">
                        <div class="timer-label">Start</div>
                        <div class="timer-label">End</div>
                    </div>
                    <div class="video-timer">
                        <div class='d-flex'>
                            <input type="number" class='time-input minutes' v-model='startTime.minutes' @input='leadingZero(startTime.minutes, "start-minutes")'>
                            <div class='time-seperator'>:</div>
                            <input type="number" class='time-input seconds' v-model='startTime.seconds' @input='leadingZero(startTime.seconds, "start-seconds")'>
                        </div>
                        <div class='d-flex'>
                            <input type="number" class='time-input minutes' v-model='endTime.minutes' @input='leadingZero(endTime.minutes, "end-minutes")'>
                            <div class='time-seperator'>:</div>
                            <input type="number" class='time-input seconds' v-model='endTime.seconds' @input='leadingZero(endTime.seconds, "end-seconds")'>
                        </div>
                    </div>
                </div>
                <br>
                <div v-if='validationError' class='validation-error'>
                    {{ validationError }}
                </div>

                <div v-if='!loader' class='btn anim-gen-btn' @click='generateAnimation'>Generate Animation</div>
                <div class='btn anim-gen-btn' v-else><Loader :centered='true'></Loader></div>
            </div>
            <div class="email-resize-options">
                <div class="thumbnail-size">
                    <div class="responsive-size d-flex size-options justify-content-between">
                        <div class="title-small">Thumbnail Size</div>
                    </div>
                    <div class="size-inputs d-flex">
                        <input type="number" class='width dimensions' v-model='width'>
                        <Popover
                            v-if='popover.aspectRatio'
                            :message="lockedAspectRatio ? 'Aspect Ratio is locked. Click to unlock.' : 'Aspect Ratio is unlocked. Click to lock.'" 
                            width="200px"
                            bottom="50px"
                            left="-19px"
                        ></Popover>
                        <div @mouseover='popover.aspectRatio = true' @mouseleave='popover.aspectRatio = false' @click='lockedAspectRatio = !lockedAspectRatio;' class="lock-icon" :class='lockedAspectRatio ? "locked" : "unlocked"'>
                            <svg id="Action_Expand" data-name="Action Expand" xmlns="http://www.w3.org/2000/svg" width="10.454" height="12.545" viewBox="0 0 10.454 12.545"><path id="Path_9388" data-name="Path 9388" d="M14.409,9H6.045A1.045,1.045,0,0,0,5,10.045v5.576a1.045,1.045,0,0,0,1.045,1.045h8.363a1.045,1.045,0,0,0,1.045-1.045V10.045A1.045,1.045,0,0,0,14.409,9Zm.348,6.621a.348.348,0,0,1-.348.348H6.045a.348.348,0,0,1-.348-.348V10.045A.348.348,0,0,1,6.045,9.7h8.363a.348.348,0,0,1,.348.348Z" transform="translate(-5 -4.121)" fill="#21455e"/><path id="Path_9389" data-name="Path 9389" d="M11.136,2A3.143,3.143,0,0,0,8,5.136V7.227a.348.348,0,0,0,.348.348h5.576a.348.348,0,0,0,.348-.348V5.136A3.143,3.143,0,0,0,11.136,2ZM8.7,6.879V5.136a2.439,2.439,0,1,1,4.879,0V6.879Z" transform="translate(-5.909 -2)" fill="#21455e"/><rect id="Rectangle_3246" data-name="Rectangle 3246" width="1" height="3" transform="translate(5 7.032)" fill="#21455e"/></svg>
                        </div>
                        <input type="number" class='height dimensions' v-model='height'>
                    </div>
                </div>
            </div>
            <div class="email-link-to">
                <p class="title-small">Link to &nbsp;&nbsp;&nbsp;
                    <Popover
                        v-if='popover.linkTo'
                        message="This is the link your subscribers will be redirected to when they click the video thumbnail" 
                        width="300px"
                        bottom="50px"
                        left="60px"
                    ></Popover>
                    <span class='info-icon' @mouseover='popover.linkTo = true' @mouseleave='popover.linkTo = false'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.529" height="12.529" viewBox="0 0 12.529 12.529"><path id="Path_9537" data-name="Path 9537" d="M11.265,5a6.265,6.265,0,1,0,6.265,6.265A6.265,6.265,0,0,0,11.265,5Zm.783,9.237a.783.783,0,1,1-1.566,0V10.716a.783.783,0,1,1,1.566,0Zm-.783-5.162a.783.783,0,1,1,.783-.783.783.783,0,0,1-.783.783Z" transform="translate(-5 -5)" fill="#21455e" opacity="0.7"/></svg>
                    </span>
                </p>
                <input type="text" class='link-to' v-model='linkTo'>
            </div>
            <div class="email-autoplay d-flex">
                <div class="autoplay-btn" @click="video_autoplay = video_autoplay ? false : true">
<!--                    <PerformanceGoalsToggle-->
<!--                        :toggle='video_autoplay'-->
<!--                        :type='"video_autoplay"'-->
<!--                    ></PerformanceGoalsToggle>-->
                </div>
                <div class="autoplay-text">
                    Autoplay video when subscriber lands on page.
                </div>
            </div>
            <div class="auto-play-preview">
                <p class="title-small">Preview</p>
                <div>
                    <div class="play-video-icon">
                        <!-- <img src="../../../../../../../assets/img/icons/play-icon.svg" alt="play-icon"> -->
                        <img :src="backendUrl + 'img/play-icon.svg'" alt="">
                    </div>
                    <a :href='linkTo' target='_blank'>
                        <img :src='linkBackgroundImage' class='auto-play-image'>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .email-embed-code .email-embed-head {
        display: flex;
        justify-content: space-between;
        width: 469px;
    }

    .email-embed-head .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        opacity: 1;
        color: #FFFFFF;
        position: relative;
    }

    .title-small {
        margin-top: 6px;
        font-family: Helvetica Neue;
        font-size: 15px;
        letter-spacing: 0px;
        color: #21455E;
        text-transform: capitalize;
        opacity: 1;
    }

    .email-embed-code textarea {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E2E5ED;
        border-radius: 6px;
        opacity: 1;
        width: 469px;
        height: 134px;
        padding: 15px 15px;
        margin-top: 15px;
        color: #98A4B3;
        resize: none;
    }

    .email-embed-options {
        padding: 15px 0;
    }

    .title {
        text-align: left;
        font-family: Helvetica Neue;
        font-size: 19px;
        color: #21455E;
        opacity: 1;
    }

    .animation-toggle-text {
        margin-left: 20px;
    }

    .timer-label {
        height: 38px;
        background: #F5F6F9;
        padding: 0 15px;
        color: #21455E;
        line-height: 2.5;
        border-radius: 5px 0 0 5px;
        margin-top: 15px;
    }

    .video-timer-labels {
        display: flex;
        flex-direction: column;
    }

    .video-timer {
        display: flex;
        flex-direction: column;
    }

    .video-timer input {
        height: 38px;
        text-align: center;
        margin-top: 15px;
        border: 1px solid #E2E5ED;
    }

    .email-resize-options .title-small {
        margin-bottom: 10px;
    }

    .size-inputs {
        position: relative;
    }

    .size-inputs .dimensions {
        width: 70px;
        height: 38px;
        border: 1px solid #E2E5ED;
    }

    .size-inputs .width {
        border-radius: 5px 0px 0px 5px;
        text-align: center;
    }

    .size-inputs .height {
        border-radius: 0px 5px 5px 0px;
        text-align: center;
    }

    .lock-icon {
        width: 25px;
        height: 38px;
        padding: 8px;
        cursor: pointer;
    }

    .locked { background: rgba(0, 172, 220, 0.178); }
    .unlocked { background: #F5F6F9; }

    .email-link-to {
        margin-top: 20px;
    }

    .email-link-to input {
        width: 475px;
        height: 38px;
        border: 1px solid #E2E5ED;
        border-radius: 5px;
        margin-top: 15px;
    }

    .email-autoplay {
        margin-top: 15px;
    }

    .autoplay-text {
        margin-left: 20px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .play-video-icon img {
        position: absolute;
        width: 70px !important;
        /* height: 70px !important; */
        left: 0;
        right: 0;
        margin: auto;
    }

    .play-video-icon i {
        font-size: 29px !important;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 20px;
        margin-left: 5px;
    }

    .auto-play-preview {
        position: relative;
    }

    .auto-play-preview img, video {
        width: 470px;
        height: 274px;
    }

    .minutes {
        border-right: none !important;
        width: 50px;
        text-align: right !important;
    }

    .seconds {
        border-left: none !important;
        width: 50px;
        text-align: left !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .time-seperator {
        height: 38px;
        margin-top: 15px;
        padding-top: 8px;
        font-weight: 700;
        border-top: 1px solid #E2E5ED;
        border-bottom: 1px solid #E2E5ED;
    }

    .anim-gen-btn {
        background: #F5F6F9;
        color: #21455E;
        position: relative;
        width: 182.97px;
        height: 36px;
    }

    .validation-error {
        color: #EE5951;
    }

        .restriction-dialog {
        font-size: 14px;
        width: 100%;
        display: flex;
        background: rgba(1, 26, 34, 0.822);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 95;
        border-radius: 12px;
        backdrop-filter: blur(8px);
        p {
            padding: 2px;
            color: #fff;
        }
    }

    .warn-sign {
        position: relative;
        width: 60px;
        height: 60px;
        text-align: center;
        margin-bottom: 15px;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }
</style>

<script>

// import PerformanceGoalsToggle from '../../../NewVideoExperiment/templates/PerformanceGoalsToggle';
import Loader from '../../../../../../Snaps/components/Loader';
import Popover from '../../../../../../Common/Popover';

export default {
    props: ['videoData', 'video', 'restricted', 'fullVideo'],
    components: {
        // PerformanceGoalsToggle,
        Loader,
        Popover,
    },
    data: () => ({
        lockedAspectRatio: true,
        width: 853,
        height: 480,
        responsive: false,
        embedUrlLink: '',
        embedUrl: '',
        thumbnail_url: '',
        videoId: '',
        video_animation: false,
        video_autoplay: false,
        gifAnimation: '#',
        videoFile: null,
        playVideo: false,
        startTime: {minutes: '00', seconds: '00'},
        endTime: {minutes: '00', seconds: '05'},
        validationError: false,
        linkTo: null,
        linkBackgroundImage: null,
        loader: false,
        popover: {
            aspectRatio: false,
            linkTo: false
        }
    }),
    mounted()
    {
        if (this.$props.restricted) return false;
        let videoData   = this.$props.videoData,
            experiment  = !isNaN(videoData.video_id) ? '?ex=true' : '';

        if (videoData) {

            if(videoData.power_player == 1){
                this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}player/${videoData.video_id}${experiment}`;
            }else{
                this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}watch/${videoData.video_id}${experiment}`;
            }
            this.videoId = videoData.video_id;
            this.thumbnail_url = videoData.thumbnail_url;
            // this.gifAnimation = this.thumbnail_url;
        }
        this.getLink();
        this.getVideoFile();
    },
    methods: {
        copyToClipboard()
        {
            let element = this.$refs.embedCode;
            if (element) {
                element.select();
                document.execCommand('copy');
            }
            this.$emit('copySuccess', true);
        },
        aspectRatio(type = 'width')
        {
            if (this.lockedAspectRatio) {
                if (type == 'width') {
                    let newHeight = this.width - 100;
                    this.height = newHeight > 0 ? newHeight : 0;
                } else {
                    let newWidth = parseInt(this.height) + 100;
                    this.width = newWidth > 0 ? newWidth : 0;
                }
            }
        },
        getLink()
        {
            if (this.$props.restricted) return false;
            if (!this.linkTo) this.linkTo = this.embedUrl;
            if (this.video_animation) this.linkBackgroundImage = this.gifAnimation;
            else this.linkBackgroundImage = this.thumbnail_url;
            const imageTag = `<img width="${this.width}" height="${this.height}" src="${this.linkBackgroundImage}">`;
            const playIconStyle = 'position: absolute; right: 0; left: 0; top: 0; bottom: 0; margin: auto';
            const playIcon = `<img style="${playIconStyle}" src="${process.env.VUE_APP_BACKEND_URL}img/play-icon.svg" />`
            const playIconContainer = `<div style="width: ${this.width}; height: ${this.height}; position: absolute; z-index: 4">${playIcon}</div>`;
            this.embedUrlLink = `<a href="${this.linkTo}" target="_blank" style="position: relative;">${playIconContainer}${imageTag}</a>`;
        },
        generateAnimation(e)
        {
            e.preventDefault();
            var startFormatted = `${this.startTime.minutes}:${this.startTime.seconds}`,
                endFormatted = `${this.endTime.minutes}:${this.endTime.seconds}`,
                startTime = this.convertToSeconds(startFormatted),
                endTime   = this.convertToSeconds(endFormatted);

            if (startTime > endTime) {
                this.validationTrigger('Start time cannot be greater than end time.');
            } else if (endTime - startTime > 20) {
                this.validationTrigger('Time difference cannot be greater than 20 seconds.');
            } else if (endTime < 1) {
                this.validationTrigger('Please select end time.');
            } else {
                console.log('Generating animation...');
                this.generateImage(startTime, endTime);
            }
            
        },
        validationTrigger(message, timeout = 3000)
        {
            if (!this.validationError) 
                this.validationError = message;
            if (timeout) {
                setTimeout(() => {
                    if (this.validationError) this.validationError = false;
                }, timeout);
            }
        },
        generateImage(startTime, endTime)
        {
            let owner = this.$props.video ? this.$props.video.owner : this.$props.videoData.owner;

            let id = null;
            if (this.$props.video) id = this.$props.video.id;
            else id = this.$props.videoData.id;
            if (this.videoFile) {
                this.loader = true;
                this.axios.post('image/create-gif', 
                    {
                        user_id: owner,
                        video_file: this.videoFile,
                        video_id: id,
                        start_time: startTime,
                        end_time: endTime
                    },
                ).then(response => {
                    if (response.data.success && response.data.file_path) this.gifAnimation = response.data.file_path;
                    else this.validationTrigger('Failed to generate video animation.', false);
                    this.getLink();
                    this.loader = false;
                }).catch(error => {
                    console.log(error);
                    this.validationTrigger('Failed to generate video animation.', false);
                    this.loader = false;
                });
            }
        },
        getVideoFile()
        {
            var files = this.$props.video ? this.$props.video.files : this.$props.videoData.files;
            
            files = typeof files == 'string' ? JSON.parse(files) : files;
            console.log(files);
            this.videoFile = this.bestQualityFile(files);
            if (!this.videoFile) console.log('No file found.');
            else console.log('Video file: ', this.videoFile);
        },
        bestQualityFile(files)
        {
            let file = null;
            if(this.$props.videoData.power_player == 0){

                if (file = files.find(f => f.includes('720p'))) return file;
                else if (file = files.find(f => f.includes('480p'))) return file;
                else if (file = files.find(f => f.includes('360p'))) return file;
                else if (file = files.find(f => f.includes('240p'))) return file;
                else if (file = files.find(f => f.includes('144p'))) return file;
                else return file;
            }
            return file
        },
        convertToSeconds(ms)
        {
            var a = ms.split(':');
            return (+a[0]) * 60 + (+a[1]);
        },
        durationValidation(n, o, type)
        {
            var seconds = this.convertToSeconds(n);
            if (seconds > this.videoData.duration_raw) {
                this[type] = o;
                if (!this.validationError) 
                    this.validationError = `Time cannot be greater than total video duration of ${this.videoData.duration_raw} seconds.`;
                setTimeout(() => {
                    if (this.validationError) this.validationError = false;
                }, 3000);
            }
        },
        leadingZero(value, type)
        {
            value = value < 0 ? '00' : value;
            value = value.length == 1 ? '0' + value : value;
            (type == 'start-minutes')   ? this.startTime.minutes    = value    : null;
            (type == 'start-seconds')   ? this.startTime.seconds    = value    : null;
            (type == 'end-minutes')     ? this.endTime.minutes      = value    : null;
            (type == 'end-seconds')     ? this.endTime.seconds      = value    : null;
        }
    },
    watch: {
        width()
        {
            this.aspectRatio('width');
            this.getLink();
        },
        height()
        {
            this.aspectRatio('height');
            this.getLink();
        },
        linkTo()
        {
            this.getLink();
        },
        lockedAspectRatio()
        {
            this.aspectRatio('width');
        },
        playVideo(n)
        {
            if (n && this.videoFile) {
                this.$refs.videoPlayer.play();
            } else {
                this.$refs.videoPlayer.pause();
            }
        },
        startTime(n, o)
        {
            if (this.convertToSeconds(n) >= this.convertToSeconds(this.endTime)) this.startTime = o;
            else this.durationValidation(n, o, 'startTime');
        },
        endTime(n, o)
        {
            if (this.convertToSeconds(n) <= this.convertToSeconds(this.startTime)) this.endTime = o;
            else this.durationValidation(n, o, 'endTime');
        },
        video_animation(n, o)
        {
            if (n) this.linkBackgroundImage = this.gifAnimation;
            else this.linkBackgroundImage = this.thumbnail_url;
            this.getLink();
        },
        video_autoplay(n, o)
        {
            if (n) this.linkTo = `${this.linkTo}?autoplay=true`;
            else {
                let from = this.linkTo.indexOf('?a');
                this.linkTo = this.linkTo.slice(0, from);
            }
        }
    }
}
</script>