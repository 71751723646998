<template>
    <div>
        <div class="inline-embed-code">
            <div class="inline-embed-head">
                <p class="title-small">Embed Code</p>
                <button @click='copyToClipboard' class="btn">Copy</button>
            </div>
            <textarea ref='embedCode' @click="copyToClipboard"  v-model='embedUrlLink'></textarea>
        </div>
        <div class="inline-embed-options">
            <p class='title'>Options</p>
            <div class="video-size">
                <p class="title-small">Video Size</p>
                <div class="video-size-options">
                    <div class="responsive-size d-flex size-options justify-content-between">
                        <div :class="video_size == 'responsive' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="video_size = 'responsive'">
                            <div :class="video_size == 'responsive' ? 'icon-inner-selected' : ''"></div>
                        </div>
                        <div class="label">Responsive</div>
                        <div class='info-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.529" height="12.529" viewBox="0 0 12.529 12.529"><path id="Path_9537" data-name="Path 9537" d="M11.265,5a6.265,6.265,0,1,0,6.265,6.265A6.265,6.265,0,0,0,11.265,5Zm.783,9.237a.783.783,0,1,1-1.566,0V10.716a.783.783,0,1,1,1.566,0Zm-.783-5.162a.783.783,0,1,1,.783-.783.783.783,0,0,1-.783.783Z" transform="translate(-5 -5)" fill="#21455e" opacity="0.7"/></svg>
                        </div>
                    </div>
                    <div class="fixed-size d-flex size-options justify-content-between">
                        <div :class="video_size == 'fixed' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="video_size = 'fixed'">
                            <div :class="video_size == 'fixed' ? 'icon-inner-selected' : ''"></div>
                        </div>
                        <div class="label">Fixed</div>
                    </div>
                    <div class="size-inputs d-flex">
                        <input type="number" class='width dimensions' v-model='width'>
                        <div @click='aspectRatio' class="lock-icon" :class='lockedAspectRatio ? "locked" : "unlocked"'>
                            <svg id="Action_Expand" data-name="Action Expand" xmlns="http://www.w3.org/2000/svg" width="10.454" height="12.545" viewBox="0 0 10.454 12.545"><path id="Path_9388" data-name="Path 9388" d="M14.409,9H6.045A1.045,1.045,0,0,0,5,10.045v5.576a1.045,1.045,0,0,0,1.045,1.045h8.363a1.045,1.045,0,0,0,1.045-1.045V10.045A1.045,1.045,0,0,0,14.409,9Zm.348,6.621a.348.348,0,0,1-.348.348H6.045a.348.348,0,0,1-.348-.348V10.045A.348.348,0,0,1,6.045,9.7h8.363a.348.348,0,0,1,.348.348Z" transform="translate(-5 -4.121)" fill="#21455e"/><path id="Path_9389" data-name="Path 9389" d="M11.136,2A3.143,3.143,0,0,0,8,5.136V7.227a.348.348,0,0,0,.348.348h5.576a.348.348,0,0,0,.348-.348V5.136A3.143,3.143,0,0,0,11.136,2ZM8.7,6.879V5.136a2.439,2.439,0,1,1,4.879,0V6.879Z" transform="translate(-5.909 -2)" fill="#21455e"/><rect id="Rectangle_3246" data-name="Rectangle 3246" width="1" height="3" transform="translate(5 7.032)" fill="#21455e"/></svg>
                        </div>
                        <input type="number" class='height dimensions' v-model='height'>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="videoData.is_audio == 1" class="video-size-options">
            <p class="title-small">Player Type</p>
            <div class="responsive-size d-flex size-options justify-content-between">
                <div :class="player_size == 'full' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="playerSize('full')">
                    <div :class="player_size == 'full' ? 'icon-inner-selected' : ''"></div>
                </div>
                <div class="label">Full Audio Player</div>
            </div>

            <div class="responsive-size d-flex size-options justify-content-between">
                <div :class="player_size == 'minimized' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="playerSize('minimized')">
                    <div :class="player_size == 'minimized' ? 'icon-inner-selected' : ''"></div>
                </div>
                <div class="label">Minimized Player</div>
            </div>
        </div>

        
        <!-- <div class="embed-type">
            <p class="title-small">Embed Type</p>
            <div class="embed-type-selection">
                <div class="d-flex iframe-embed justify-content-between" :class="summaryPage ? 'w-303' : ''">
                    <div :class="embed_type == 'iframe' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click='embed_type = "iframe"'>
                        <div :class="embed_type == 'iframe' ? 'icon-inner-selected' : ''"></div>
                    </div>
                    <div class="label" :class='summaryPage ? "line-height" : ""'>Iframe-based Embed Code <span class='hints'>(Fallback)</span></div>
                </div> -->



<!--                <div class="d-flex javascript-embed justify-content-between" :class="summaryPage ? 'w-330' : ''">-->
<!--                    <div :class="embed_type == 'javascript' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click='embed_type = "javascript"'>-->
<!--                        <div :class="embed_type == 'javascript' ? 'icon-inner-selected' : ''"></div>-->
<!--                    </div>-->
<!--                    <div class="label" :class='summaryPage ? "line-height" : ""'>Javascript Embed Code <span class='hints'>(Recommended)</span></div>-->
<!--                </div>-->


            <!-- </div>
        </div> -->


<!--        <div class="seo-metadata">-->
<!--            <p class="title-small">SEO Metadata</p>-->
<!--            <div class="seo-options d-flex" :class="!summaryPage ? 'justify-content-between' : ''">-->
<!--                <svg @click='seo_metadata = false' v-if='seo_metadata' id="Checked" xmlns="http://www.w3.org/2000/svg" width="17.371" height="17.371" viewBox="0 0 17.371 17.371"><path id="Rectangle-4-Copy-6" d="M24.343,152.5h8.686a4.343,4.343,0,0,1,4.343,4.343v8.686a4.343,4.343,0,0,1-4.343,4.343H24.343A4.343,4.343,0,0,1,20,165.528v-8.686A4.343,4.343,0,0,1,24.343,152.5Z" transform="translate(-20 -152.5)" fill="#21455e" fill-rule="evenodd"/><path id="Path_8230" data-name="Path 8230" d="M8.951,68.131a.454.454,0,0,0-.642,0L2.867,73.573.775,71.481a.454.454,0,0,0-.642.642l2.413,2.413a.454.454,0,0,0,.642,0l5.763-5.763A.454.454,0,0,0,8.951,68.131Z" transform="translate(4.001 -62.764)" fill="#fff" stroke="#fff" stroke-width="0.2"/></svg>-->
<!--                <svg @click='seo_metadata = true' v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.371" height="17.371" viewBox="0 0 20 20"><defs><clipPath id="clip-path"><rect width="20" height="20" fill="none"/></clipPath></defs><g id="Unchecked" clip-path="url(#clip-path)"><path id="Rectangle-4-Copy" d="M27,68.5A4.5,4.5,0,0,0,22.5,73V83A4.5,4.5,0,0,0,27,87.5H37A4.5,4.5,0,0,0,41.5,83V73A4.5,4.5,0,0,0,37,68.5Z" transform="translate(-22 -68)" fill="#fff" stroke="#a3bac6" stroke-width="1" fill-rule="evenodd"/></g></svg>-->

<!--                &lt;!&ndash; <div @click='seo_metadata = true' v-else class="unchecked"></div> &ndash;&gt;-->
<!--                <p>Inject the Video Metadata in any page the video is embedded for Search Engines to index.</p>-->
<!--            </div>-->
<!--            <div class="seo-info-buttons d-flex justify-content-between">-->
<!--                <button @click='triggerMetadata(true)' class='btn'>See Video Metadata</button>-->
<!--                <button class='btn'>Learn more about Video SEO</button>-->
<!--            </div>-->
<!--        </div>-->
        <div v-if='showMetadata'>
            <VideoMetadata :metadata='type == "thumbnails" ? metadata : metadataVideos' @trigger='triggerMetadata' @closeMainModal='closeMainModal' :summaryPage='summaryPage'></VideoMetadata>
        </div>
    </div>
</template>

<style scoped>

    .inline-embed-code .inline-embed-head {
        display: flex;
        justify-content: space-between;
        width: 469px;
    }

    .inline-embed-head .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        opacity: 1;
        color: #FFFFFF;
    }

    .title-small {
        margin-top: 6px;
        font-family: Helvetica Neue;
        font-size: 15px;
        letter-spacing: 0px;
        color: #21455E;
        text-transform: capitalize;
        opacity: 1;
    }

    .inline-embed-code textarea {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E2E5ED;
        border-radius: 6px;
        opacity: 1;
        width: 469px;
        height: 134px;
        padding: 15px 15px;
        margin-top: 15px;
        color: #98A4B3;
        resize: none;
    }

    .inline-embed-options { margin-top: 15px; }
    .inline-embed-options .title {
        text-align: left;
        font-family: Helvetica Neue;
        font-size: 19px;
        color: #21455E;
        opacity: 1;
    }

    .video-size-options .responsive-size {
        width: 150px;
        margin-top: 20px;
    }

    .video-size-options .fixed-size {
        width: 75px;
        margin-top: 20px;
    }

    .video-size { margin-top: 20px; border-top: 1px solid #E2E5ED; }
    .video-size .video-size-options { margin-left: 25px;}
    .video-size .video-size-options .label {
        line-height: 15px;
        font-family: Helvetica Neue;
    }

    .icon-outer-selected {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 2px solid #21455E;
    }

    .icon-inner-selected {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: #21455E 0% 0% no-repeat padding-box;
        position: relative;
        left: 0;
        top: 2px;
        right: 0;
        margin: auto;
    }

    .icon-outer-unselected {
        border: 2px solid #D8DCE6;
        border-radius: 8px;
        width: 16px;
        height: 16px;
    }

    .unselected {
        border: 2px solid #D8DCE6;
    }

    .size-inputs .dimensions {
        width: 70px;
        height: 38px;
        border: 1px solid #E2E5ED;
        margin-top: 10px;
    }

    .size-inputs .width {
        border-radius: 5px 0px 0px 5px;
        margin-left: 40px;
        text-align: center;
    }

    .size-inputs .height {
        border-radius: 0px 5px 5px 0px;
        text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .lock-icon {
        width: 25px;
        height: 38px;
        margin-top: 10px;
        padding: 8px;
        cursor: pointer;
    }

    .locked { background: rgba(0, 172, 220, 0.178); }
    .unlocked { background: #F5F6F9; }

    .embed-type {
        border-top: 1px solid #E2E5ED;
        margin-top: 20px;
    }

    .embed-type .embed-type-selection .javascript-embed {
        width: 300px;
        margin-left: 25px;
        margin-top: 20px;
    }

    .embed-type .embed-type-selection .iframe-embed {
        width: 275px;
        margin-left: 25px;
        margin-top: 20px;
    }

    .embed-type .embed-type-selection .hints { color: #21455EB3; }
    .embed-type .embed-type-selection input { display: none; }

    .seo-metadata {
        border-top: 1px solid #E2E5ED;
        margin-top: 20px;
    }

    .seo-metadata .seo-options {
        margin-left: 25px;
        width: 469px;
    }

    .seo-metadata .seo-options p {
        margin-left: 30px;
        color: #21455E;
        opacity: 1;
    }

    .seo-metadata .seo-info-buttons .btn {
        background: transparent;
        color: #00ACDC;
        margin-left: 42px;
        font-family: Helvetica Neue;
        font-size: 14px;
        font-weight: 200;
        overflow: hidden;
    }

    .seo-metadata .unchecked {
        width: 17px;
        height: 13px;
        border: 2px solid #D8DCE6;
        margin-top: 3px;
        border-radius: 3px;
    }

    .w-330 {
        width: 330px !important;
    }

    .w-303 {
        width: 303px !important;
    }

    .line-height {
        line-height: 1;
    }

    ::-webkit-scrollbar { width: 20px; }
    ::-webkit-scrollbar-track { background: rgb(255, 255, 255); }
    ::-webkit-scrollbar-thumb {
        background: #C9EDF8;
        background-clip: padding-box;
        border: 15px solid rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb:hover { background: #C9EDF8; }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

</style>

<script>
import VideoMetadata from './VideoMetadata';
export default {
    props: ['videoData', 'summaryPage', 'watchUrl'],
    components: {
        VideoMetadata
    },
    data: () => ({
        embedUrl: '',
        width: 860,
        height: 453,
        embed_type: 'iframe',
        video_size: 'fixed',
        player_size: 'full',
        seo_metadata: false,
        embedUrlLink: '',
        popover:  '',
        videoId: '',
        lockedAspectRatio: true,
        showMetadata: false,
        metadata: {
            title: '',
            description: '',
            embed_url: '',
            thumbnail_url: '',
            duration: ''
        },
        metadataVideos: {},
        type: null,


    }),
    mounted()
    {
        let videoData = this.$props.videoData;
        
        if (videoData) {
            // Single Video
            if (videoData.experiment_type == 'thumbnails') {
                this.type = 'thumbnails';

                if(videoData.power_player == 1){
                    // this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}player/${videoData.video_id}`;
                    // this.embedUrl = `${this.watchUrl}watch/${videoData.video_id}`;
                    this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}watch/${videoData.video_id}`;
                }else{
                    // this.embedUrl = `${this.watchUrl}watch/${videoData.video_id}`;
                    this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}watch/${videoData.video_id}`;
                }
                this.videoId = videoData.video_id;
                this.metadata.title = videoData.title;
                this.metadata.description = videoData.description;
                this.metadata.embed_url = videoData.embed_url;
                this.metadata.duration = videoData.duration;
                this.metadata.thumbnail_url = videoData.thumbnail_url;
                this.metadata.id = this.videoId;
            } else {
                // Two Videos
                this.type = 'videos';
                if(videoData.power_player == 1){
                    this.embedUrl = `${this.watchUrl}watch/${videoData.video_id}?ex=true`;
                    // this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}player/${videoData.video_id}?ex=true`;
                }else{

                    this.embedUrl = `${this.watchUrl}watch/${videoData.video_id}?ex=true`;
                }
                this.videoId = videoData.video_id;
                if (videoData.videos) {
                    this.metadataVideos = {
                        a: {
                            title: videoData.videos.a.title,
                            description: videoData.videos.a.description,
                            embed_url: videoData.videos.a.embed_url,
                            duration: videoData.videos.a.duration,
                            thumbnail_url: videoData.videos.a.thumbnail,
                            id: videoData.videos.a.id
                        },
                        b: {
                            title: videoData.videos.b.title,
                            description: videoData.videos.b.description,
                            embed_url: videoData.videos.b.embed_url,
                            duration: videoData.videos.b.duration,
                            thumbnail_url: videoData.videos.b.thumbnail,
                            id: videoData.videos.b.id
                        },
                    }
                }

            }
        }
        this.getLink();
        this.popover = '';
        // this.popover = `${this.backendUrl}${this.popover}`;
    },
    watch: {
        width(n, o) {
            if(this.player_size !== "minimized"){
                this.height = this.lockedAspectRatio ? parseInt(n) - 100 : this.height;
            }
            this.getLink()
        },
        height(n, o) {
            if(this.player_size !== "minimized") {
                this.width = this.lockedAspectRatio ? parseInt(n) + 100 : this.width;
            }
            this.getLink()
        },
        video_size() { this.getLink() },
        embed_type() {this.getLink() },
        player_size() {this.getLink() }
    },
    methods: {
        playerSize(type){
            this.player_size = type;

            if(type == 'minimized'){
                this.height = 209;
                this.width = 738;
            }else{
                this.height = 860;
            }
        },
        getLink()
        {
            let embedJs = this.embed_type == 'javascript',
                link = '';
// <div style="width: 100%; height: 100%; padding-top: 56.25%; position: relative">
// <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true" src="https://adilo.bigcommand.com/watch/LEd9UnMb" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no"></iframe>
// </div>
            if (!embedJs) {
                 // Iframe
                // let responsive = this.video_size == 'responsive' ? 'style="width: 100% !important;height: 100% !important' : '';
                let responsive = this.video_size == 'responsive' ? true : false;
                let width = this.width + 'px';
                let height = this.height + 'px';
                let paddingTop = '';
                if (responsive) {
                    width = '100%';
                    height = 'null';
                    paddingTop= 'padding-top: 56.25%;';
                }
                link = `<div style="width: ${width}; height: ${height}; position: relative; ${paddingTop}">`;
                link += `<iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true" src="${this.embedUrl} ${this.player_size == 'full' ? '': '?minified=true'}" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no"></iframe>`;
                link += '</div>';
                this.embedUrlLink = link;
            } else {
                 // Javascript
                let responsive = this.video_size == 'responsive';
                let domain = this.watchUrl.includes('bigcommand.com') ? 'https://cdn.bigcommand.com/' : this.watchUrl;
                let url        = `${domain}dynamic-embed/js/inline.js`;

                // link = `&lt;script type="text/javascript" src="${this.popover}" async=""&gt;&lt;/script&gt;<div class="motion_popover" data-options="width=${responsive ? "100%" : this.width} height='${this.height}' video='${this.videoId}' data-id='${this.videoId}'></div>`;
                let ifResponsive = responsive ? 'style="width: 100%; height: 100%; padding-top: 56.25%; position: relative;"' : 'style="width: '+this.width+'px; height: '+this.height+'px; position: relative;"';
                // let ifResponsive = this.width == '100%' ? 'style="padding-top: 56.25%; position: relative"' : '';
                console.log(ifResponsive, 'ifResponsive');
                let link = `&lt;script type="text/javascript" src="${url}" async &gt;&lt;/script&gt;<div class="motion_popover" data-id='${this.videoId}' ${ifResponsive} data-type='thumbnail'></div>`;
                // let link = `&lt;script type="text/javascript" src="${url}" async &gt;&lt;/script&gt;<div class="motion_popover" data-width='${responsive ? "100%" : this.width}' data-height='${responsive ? "100%" : this.height}' data-id='${this.videoId}' ${ifResponsive} data-type='thumbnail'></div>`;
                link = link.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                this.embedUrlLink = link;
            }
            // this.embedUrlLink = link;
        },
        copyToClipboard()
        {
            let element = this.$refs.embedCode;
            if (element) {
                element.select();
                document.execCommand('copy');
                this.$emit('copySuccess', true);
            }
        },
        aspectRatio()
        {
            this.lockedAspectRatio = this.lockedAspectRatio ? false : true;
            this.height = this.width - 100;
        },
        triggerMetadata(trigger) {
            this.showMetadata = trigger;
        },
        closeMainModal()
        {
            this.$emit('closeModal', false);
        }
    }
}
</script>