<template>
    <div class="share-embed-code" style='position: relative;'>
        <div class="restriction-dialog" v-if='restricted'>
            <div class="warn-sign"><img src="../../../../../../../../static/img/exclamation.svg" alt=""></div>
            <p>Your current plan does not include Link video embed.</p>
            <p>Please upgrade to one of our premium plans to get access to this feature.</p>
            <el-button round class="restriction_dialog_btn" type="success" @click="$emit('upgrade')"> See Upgrade
                Options
            </el-button>
        </div>
        <div class="share-embed-head">
            <p class="title-small">Public Share Link</p>
<!--            <p class='description-text'>-->
<!--                Link viewers are redirected to your Stage Page to watch the video.-->
<!--                When links are shared on social media such as Facebook, Twitter, -->
<!--                Youtube or Linkedin, the video thumbnail, title and description -->
<!--                will be shown but the video will not play inline; instead, -->
<!--                viewers are redirected to your Stage Page to watch the video.-->
<!--            </p>-->
        </div>
        <div class="share-link">
            <div class="link-container d-flex">
                <div class="link-icon"><svg id="Group_10998" data-name="Group 10998" xmlns="http://www.w3.org/2000/svg" width="18.088" height="18.088" viewBox="0 0 18.088 18.088"><circle id="Ellipse_4260" data-name="Ellipse 4260" cx="9.044" cy="9.044" r="9.044" fill="#00acdc"/><g id="unlink" transform="translate(8.626 2.071) rotate(45)"><path id="Path_2376" data-name="Path 2376" d="M5.283.648a2.219,2.219,0,0,0-3.134,0L.582,2.215a.341.341,0,0,0,.482.482L2.631,1.13A1.534,1.534,0,0,1,4.8,3.3L2.752,5.349a1.536,1.536,0,0,1-2.17,0A.341.341,0,1,0,.1,5.831a2.219,2.219,0,0,0,3.134,0L5.283,3.782a2.221,2.221,0,0,0,0-3.134Z" transform="translate(3.44 0)" fill="#fff"/><path id="Path_2377" data-name="Path 2377" d="M4.624,3.9,3.3,5.228a1.534,1.534,0,0,1-2.17-2.17L3.058,1.13a1.54,1.54,0,0,1,2.169,0A.341.341,0,1,0,5.709.648a2.219,2.219,0,0,0-3.134,0L.647,2.577A2.216,2.216,0,0,0,3.781,5.71L5.106,4.384A.341.341,0,0,0,4.624,3.9Z" transform="translate(0 3.013)" fill="#fff"/></g></svg></div>
                <div ref='code' class="link-url clipped">{{ embedUrl2 }}</div>
            </div>
            <div class="link-buttons">
<!--                <a href="#">Learn More</a>-->
                <button class="btn copy-button" @click='copyToClipboard'>Copy</button>
            </div>
        </div>
        <div class="link-time d-flex">
            <div>
                <svg @click='linkTime = false' v-if='linkTime' id="Checked" xmlns="http://www.w3.org/2000/svg" width="17.371" height="17.371" viewBox="0 0 17.371 17.371"><path id="Rectangle-4-Copy-6" d="M24.343,152.5h8.686a4.343,4.343,0,0,1,4.343,4.343v8.686a4.343,4.343,0,0,1-4.343,4.343H24.343A4.343,4.343,0,0,1,20,165.528v-8.686A4.343,4.343,0,0,1,24.343,152.5Z" transform="translate(-20 -152.5)" fill="#21455e" fill-rule="evenodd"/><path id="Path_8230" data-name="Path 8230" d="M8.951,68.131a.454.454,0,0,0-.642,0L2.867,73.573.775,71.481a.454.454,0,0,0-.642.642l2.413,2.413a.454.454,0,0,0,.642,0l5.763-5.763A.454.454,0,0,0,8.951,68.131Z" transform="translate(4.001 -62.764)" fill="#fff" stroke="#fff" stroke-width="0.2"/></svg>
                <div @click='linkTime = true' v-else class="unchecked"></div>
            </div>
            <p class="link-time-description">Link to a Specific Time in the Video, Video will autoplay on page load.</p>
        </div>
        <div class="time-controls d-flex">
            <div class="video-timer-labels">
                <div class="timer-label">Time</div>
            </div>
            <div class='d-flex video-timer'>
                <input type="number" class='time-input minutes' v-model='startTime.minutes' @input='leadingZero(startTime.minutes, "start-minutes")'>
                <div class='time-seperator'>:</div>
                <input type="number" class='time-input seconds' v-model='startTime.seconds' @input='leadingZero(startTime.seconds, "start-seconds")'>
            </div>
        </div>
        <div v-if='validationError' class='validation-error'>
            Start time cannot be greater than total video duration of <b>{{ totalDuration }} seconds.</b>
        </div>
        <!-- <div class="link-publish">
            <p class="title-small">Publish to Social Media</p>
            <p class='link-description-text'>
                Want to publish this video as a native post on your Social Media platforms?
            </p>
            <a href="#">Go to Social Media Publishing</a>
        </div> -->
    </div>
</template>
<style scoped>

    .share-embed-head .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        opacity: 1;
        color: #FFFFFF;
    }

    .title-small {
        margin-top: 6px;
        font-family: Helvetica Neue;
        font-size: 15px;
        letter-spacing: 0px;
        color: #21455E;
        text-transform: capitalize;
        opacity: 1;
    }

    .title {
        text-align: left;
        font-family: Helvetica Neue;
        font-size: 19px;
        color: #21455E;
        opacity: 1;
    }

    .description-text {
        color: rgba(33, 69, 94, 0.664);
        font-size: 14px;
        text-align: justify;
    }

    .link-description-text {
        color: rgba(33, 69, 94, 0.664);
        font-size: 14px;
    }

    .link-container {
        background: #EBF9FA;
        width: 270px;
        height: 30px;
        padding: 5px;
        margin-top: 10px;
        border-radius: 10px;
    }

    .link-buttons {
        display: flex;
        flex-direction: column;
        width: 90px;
    }

    .share-link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 469px;
    }

    .link-buttons .btn {
        background: #00ACDC 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 19px;
        font-weight: 200;
        opacity: 1;
        color: #FFFFFF;
        margin-top: 5px;
    }

    .link-url, .link-icon { margin-left: 5px; color: #00ACDC; }

    .unchecked {
        width: 17px;
        height: 17px;
        border: 2px solid #D8DCE6;
        border-radius: 3px;
    }

    .link-time {
        margin-top: 30px;
        margin-left: 15px;
    }

    .link-time-description {
        color: #21455E;
        margin-left: 15px;
        font-size: 13px;
    }

    .timer-label {
        height: 38px;
        background: #F5F6F9;
        padding: 0 15px;
        color: #21455E;
        line-height: 2.5;
        border-radius: 5px 0 0 5px;
        margin-top: 15px;
    }

    .video-timer-labels {
        display: flex;
        flex-direction: column;
    }

    .video-timer input {
        height: 38px;
        border: 1px solid #E2E5ED;
        text-align: center;
        margin-top: 15px;
    }

    .time-controls {
        margin-left: 45px;
        margin-bottom: 15px;
    }

    .link-publish {
        margin-top: 50px;
    }

    .clipped {
        width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .minutes {
        border-right: none !important;
        width: 50px;
        text-align: right !important;
    }

    .seconds {
        border-left: none !important;
        width: 50px;
        text-align: left !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .time-seperator {
        height: 38px;
        margin-top: 15px;
        padding-top: 8px;
        font-weight: 700;
        border-top: 1px solid #E2E5ED;
        border-bottom: 1px solid #E2E5ED;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
            .restriction-dialog {
        font-size: 14px;
        width: 100%;
        display: flex;
        background: rgba(1, 26, 34, 0.822);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 95;
        border-radius: 12px;
        backdrop-filter: blur(8px);
    }
    .restriction-dialog p {
              padding: 2px;
            color: #fff;
    }

</style>
<script>
export default {
    props: ['videoData', 'restricted', 'watchUrl'],
    data: () => ({
        linkTime: false,
        embedUrl: '',
        startTime: {minutes: '00', seconds: '00'},
        totalDuration: null,
        validationError: false,
        embedUrl2: '',
        experiment: false
    }),
    created()
    {
        let videoData = this.$props.videoData;
        this.experiment = isNaN(videoData.video_id) ? '' : '?ex=true';

        if (videoData) {
            if(videoData.power_player == 1){
                this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}player/${videoData.video_id}${this.experiment}`;
                this.embedUrl2 = `${process.env.VUE_APP_BACKEND_URL}player/${videoData.video_id}${this.experiment}`;
            }else{

                this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}watch/${videoData.video_id}${this.experiment}`;
                this.embedUrl2 = `${process.env.VUE_APP_BACKEND_URL}watch/${videoData.video_id}${this.experiment}`;
            }
            this.totalDuration = videoData.duration_raw;
        }
    },
    methods: {
        copyToClipboard()
        {
            let url = this.$refs.code.textContent;
            if (url) {
                let el = document.createElement('textarea');
                el.value = url; 
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$emit('copySuccess', true);
            }
        },
        setUrl(n)
        {
            // this.embedUrl = this.$props.videoData.url;
            let startTime = `${this.startTime.minutes}:${this.startTime.seconds}`;
            let queryCondition = this.experiment ? '&' : '?';
            if (n) {
                this.embedUrl2 = `${this.embedUrl}${queryCondition}start=${this.convertToSeconds(startTime)}&autoplay=true`;
            } else {
                this.embedUrl2 = this.embedUrl;
            }
        },
        convertToSeconds(ms)
        {
            var a = ms.split(':');
            return (+a[0]) * 60 + (+a[1]);
        },
        leadingZero(value, type)
        {
            value = value < 0 ? '00' : value;
            value = value.length == 1 ? '0' + value : value;
            (type == 'start-minutes')   ? this.startTime.minutes    = value    : null;
            (type == 'start-seconds')   ? this.startTime.seconds    = value    : null;
        }
    },
    watch: {
        startTime: {
            handler(n, o)
            {
                var ms = `${n.minutes}:${n.seconds}`;
                var seconds = this.convertToSeconds(ms);

                if (seconds > this.totalDuration) {
                    this.startTime = o;
                    if (!this.validationError) this.validationError = true;
                    setTimeout(() => {
                        if (this.validationError) this.validationError = false;
                    }, 3000);
                } else {
                    this.linkTime ? this.setUrl(true) : null;
                }
            },
            deep: true
        },
        linkTime(n, o)
        {
            this.setUrl(n);
        },
    }
}
</script>