<template>
    <div class='modal-sidebar-content'>
        <div class="sidebar-buttons" @click='sectionTrigger' data-type='inline' :class='currentSection=="inline" ? "selected-background" : ""'>
            <svg xmlns="http://www.w3.org/2000/svg" width="102" height="35" viewBox="0 0 28.397 28.397"><g id="Group_12645" data-name="Group 12645" transform="translate(-392 -207)"><path :class='currentSection =="inline" ? "selected-svg" : ""' id="Path_9506" data-name="Path 9506" d="M392,208.42a1.419,1.419,0,0,1,1.42-1.42h25.558a1.419,1.419,0,0,1,1.42,1.42v1.42a1.419,1.419,0,0,1-1.42,1.42H393.42a1.419,1.419,0,0,1-1.42-1.42Z" transform="translate(0 0)" fill="rgba(33,69,94,0.7)" fill-rule="evenodd" opacity="0.3"/><path :class='currentSection =="inline" ? "selected-svg" : ""' id="Path_9507" data-name="Path 9507" d="M392,219.13a2.13,2.13,0,0,1,2.13-2.13h24.138a2.13,2.13,0,0,1,2.13,2.13v9.939a2.13,2.13,0,0,1-2.13,2.13H394.13a2.13,2.13,0,0,1-2.13-2.13Z" transform="translate(0 -2.901)" fill="#21455e" fill-rule="evenodd"/><path :class='currentSection =="inline" ? "selected-text" : ""' id="icons" data-name="Path 9508" d="M414.275,224.967a.711.711,0,0,1,0,1.2l-4.383,2.8a.71.71,0,0,1-1.092-.6v-5.6a.71.71,0,0,1,1.092-.6Z" transform="translate(-4.873 -4.367)" fill="#fff" fill-rule="evenodd"/><path :class='currentSection =="inline" ? "selected-svg" : ""' id="Path_9509" data-name="Path 9509" d="M392,242.42a1.419,1.419,0,0,1,1.42-1.42h25.558a1.419,1.419,0,0,1,1.42,1.42v1.42a1.419,1.419,0,0,1-1.42,1.42H393.42a1.419,1.419,0,0,1-1.42-1.42Z" transform="translate(0 -9.862)" fill="rgba(33,69,94,0.7)" fill-rule="evenodd" opacity="0.3"/></g></svg>
            <p class='text-center' :class='currentSection=="inline" ? "selected-text" : ""'>Inline <br> Embed</p>
        </div>
<!--        <div class="sidebar-buttons" v-if="this.videoData.is_audio == 0" @click='sectionTrigger' data-type='popover' :class='currentSection=="popover" ? "selected-background" : ""'>-->
<!--            <svg height="35" viewBox="0 0 28.444 28.444" width="102" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><g fill="#21455e"><path :class='currentSection =="popover" ? "selected-svg" : ""' d="m392 316.422a1.422 1.422 0 0 1 1.422-1.422h25.6a1.422 1.422 0 0 1 1.422 1.422v1.422a1.422 1.422 0 0 1 -1.422 1.422h-25.6a1.422 1.422 0 0 1 -1.422-1.422z" opacity=".3" transform="translate(-392 -315)"/><path :class='currentSection =="popover" ? "selected-svg" : ""' d="m392 327.422a1.422 1.422 0 0 1 1.422-1.422h25.6a1.422 1.422 0 0 1 1.422 1.422v1.422a1.422 1.422 0 0 1 -1.422 1.422h-25.6a1.422 1.422 0 0 1 -1.422-1.422z" opacity=".3" transform="translate(-392 -318.178)"/><path :class='currentSection =="popover" ? "selected-svg" : ""' d="m392 350.422a1.422 1.422 0 0 1 1.422-1.422h25.6a1.422 1.422 0 0 1 1.422 1.422v1.422a1.422 1.422 0 0 1 -1.422 1.422h-25.6a1.422 1.422 0 0 1 -1.422-1.422z" opacity=".3" transform="translate(-392 -324.822)"/><path :class='currentSection =="popover" ? "selected-svg" : ""' d="m392 339.422a1.422 1.422 0 0 1 1.422-1.422h25.6a1.422 1.422 0 0 1 1.422 1.422v1.422a1.422 1.422 0 0 1 -1.422 1.422h-25.6a1.422 1.422 0 0 1 -1.422-1.422z" opacity=".3" transform="translate(-392 -321.645)"/><path :class='currentSection =="popover" ? "selected-svg" : ""' d="m398 327.422a1.422 1.422 0 0 1 1.422-1.422h17.067a1.422 1.422 0 0 1 1.422 1.422v9.956a1.422 1.422 0 0 1 -1.422 1.422h-17.067a1.422 1.422 0 0 1 -1.422-1.422z" transform="translate(-393.733 -318.178)"/></g><path :class='currentSection =="popover" ? "selected-text" : ""' d="m414.284 332.972a.712.712 0 0 1 0 1.2l-4.39 2.8a.711.711 0 0 1 -1.094-.6v-5.609a.711.711 0 0 1 1.094-.6z" fill="#fff" transform="translate(-396.854 -319.349)" id='icons'/></g></svg>-->
<!--            <p class='text-center' :class='currentSection =="popover" ? "selected-text" : ""'>Popover <br> Embed</p>-->
<!--        </div>-->
<!--        <div class="sidebar-buttons" v-if="this.videoData.is_audio == 0" @click='sectionTrigger' data-type='email' :class='currentSection =="email" ? "selected-background" : ""'>-->
<!--            <svg height="35" viewBox="0 0 28.735 29.283" width="102" xmlns="http://www.w3.org/2000/svg"><path :class='currentSection =="email" ? "selected-svg" : ""' d="m405.668 423.357a.664.664 0 0 1 .806 0l13.951 10.264h-28.7z" fill="#21455e" fill-rule="evenodd" opacity=".3" transform="translate(-391.704 -423.22)"/><path :class='currentSection =="email" ? "selected-svg" : ""' d="m393 438h26.735v15.478a1.407 1.407 0 0 1 -1.407 1.407h-23.92a1.407 1.407 0 0 1 -1.408-1.407z" fill="none" opacity=".3" stroke="#21455e" stroke-width="2" transform="translate(-392 -426.602)"/><g fill-rule="evenodd"><path :class='currentSection =="email" ? "selected-svg" : ""' d="m398 444.407a1.407 1.407 0 0 1 1.407-1.407h16.885a1.407 1.407 0 0 1 1.407 1.407v7.035a1.407 1.407 0 0 1 -1.407 1.407h-16.885a1.407 1.407 0 0 1 -1.407-1.406z" fill="#21455e" transform="translate(-393.482 -428.084)"/><path :class='currentSection =="email" ? "selected-text" : ""' d="m413.437 448.368a.7.7 0 0 1 0 1.186l-2.755 1.76a.7.7 0 0 1 -1.082-.592v-3.522a.7.7 0 0 1 1.082-.593z" id='icons' fill="#fff" transform="translate(-396.921 -429.121)"/></g></svg>-->
<!--            <p :class='currentSection =="email" ? "selected-text" : ""' class='text-center'>Email <br> Campaign</p>-->
<!--        </div>-->
        <div class="sidebar-buttons" @click='sectionTrigger' data-type='share' :class='currentSection =="share" ? "selected-background" : ""'>
            <svg height="35" viewBox="0 0 27.542 28.248" width="102" xmlns="http://www.w3.org/2000/svg"><g fill="#21455e" fill-rule="evenodd"><path :class='currentSection =="share" ? "selected-svg" : ""' d="m421.943 566.887a4.943 4.943 0 1 1 4.943-4.943 4.943 4.943 0 0 1 -4.943 4.943z" transform="translate(-399.345 -538.639)"/><path :class='currentSection =="share" ? "selected-svg" : ""' d="m421.943 540.887a4.943 4.943 0 1 1 4.943-4.944 4.943 4.943 0 0 1 -4.943 4.944z" transform="translate(-399.345 -531)"/><path :class='currentSection =="share" ? "selected-svg" : ""' d="m396.943 553.887a4.943 4.943 0 1 1 4.943-4.943 4.943 4.943 0 0 1 -4.943 4.943z" transform="translate(-392 -534.819)"/></g><path :class='currentSection =="share" ? "selected-svg" : ""' d="m416.536 537.775-17.8 9.323 17.8 9.671" fill="none" opacity=".3" stroke="#21455e" stroke-width="2" transform="translate(-393.98 -532.99)"/></svg>
            <p :class='currentSection =="share" ? "selected-text" : ""' class='text-center'>Share <br> Link</p>
        </div>
        <div v-if="videoData.power_player == 0" class="sidebar-buttons" @click='sectionTrigger' data-type='links' :class='currentSection =="links" ? "selected-background" : ""'>
            <svg xmlns="http://www.w3.org/2000/svg" width="102" height="35" viewBox="0 0 27.542 28.248">
                <g id="mp4-file-format-symbol" transform="translate(-75.6)" >
                    <g id="Group_16943" data-name="Group 16943" transform="translate(75.6 0)">
                        <path id="Path_9899" :class='currentSection =="links" ? "selected-svg" : ""' data-name="Path 9899" d="M100.861,8.346A.678.678,0,0,0,100.7,7.9L93.983.234l-.005,0a.683.683,0,0,0-.135-.114L93.8.088a.738.738,0,0,0-.135-.056c-.013,0-.024-.009-.037-.012A.666.666,0,0,0,93.47,0h-16.5A1.368,1.368,0,0,0,75.6,1.366v32.1a1.367,1.367,0,0,0,1.366,1.366H99.5a1.367,1.367,0,0,0,1.366-1.366V8.423A.764.764,0,0,0,100.861,8.346ZM77.507,27.665l-.541-26.3H92.787V8.354a.683.683,0,0,0,.683.683H99.5l.542,18.628Z" transform="translate(-75.6 0)" fill="#21455e"/>
                        <g id="Group_16945" data-name="Group 16945" transform="translate(6.605 13.5)">
                            <path id="Path_9507" data-name="Path 9507" d="M392,217.9a.9.9,0,0,1,.9-.9h10.25a.9.9,0,0,1,.9.9v4.221a.9.9,0,0,1-.9.9H392.9a.9.9,0,0,1-.9-.9Z" transform="translate(-392 -217)" fill="#21455e" fill-rule="evenodd"/>
                            <path id="Path_9508" data-name="Path 9508" d="M411.125,223.291a.3.3,0,0,1,0,.508l-1.861,1.189a.3.3,0,0,1-.464-.254v-2.378a.3.3,0,0,1,.464-.254Z" transform="translate(-403.735 -220.53)" fill="#fff" fill-rule="evenodd"/>
                        </g>
                    </g>
                </g>
            </svg>
            <p :class='currentSection =="links" ? "selected-text" : ""' class='text-center'>File <br> Links</p>
        </div>
    </div>
</template>

<style scoped>

    .modal-sidebar-content {
        height: 100%;
        width: 102px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border-right: 1px solid #00000014;
        overflow-x: hidden;
        margin-top: 50px;
    }

    .sidebar-buttons {
        height: 113px !important;
        border-bottom: 1px solid #00000014;
        cursor: pointer;
    }

    .sidebar-buttons svg {
        margin-top: 20px;
    }

    .sidebar-buttons p {
        font: Semibold 13px/16px Proxima Nova;
        letter-spacing: 0px;
        color: #21455E;
        text-transform: capitalize;
        opacity: 1;
    }

    .hover-bg { background: #EBF9FA; }
    .hover-color { color: #00ACDC; }
    .hover-fill { fill: #00ACDC; }

    .sidebar-buttons:hover {
        background: #EBF9FA;
    }

    .sidebar-buttons:hover p {
        color: #00ACDC;
    }

    .sidebar-buttons:hover path {
        fill: #00ACDC;
    }
    .sidebar-buttons:hover #icons {
        fill: #EBF9FA;
    }

    .selected-background { background: #EBF9FA; }
    .selected-text { color: #00ACDC !important; }
    .selected-svg { fill: #00ACDC; }
    .selected-icon { fill: #EBF9FA; }


</style>

<script>
export default {
    name: 'EmbedModalSidebar',
    props: ['videoData'],
    data: () => ({
        currentSection: ''
    }),
    created(){
        // console.log(this.videoData, 'videoData 78');
    },
    methods: {
        sectionTrigger(e)
        {
            let type = e.currentTarget.getAttribute('data-type');
            this.currentSection = type;
            this.$emit('section', type);
        }
    }
}
</script>