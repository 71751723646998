<template>
    <div class='description-modal'>
        <div class="description-modal-content">
            <div class="description-modal-header d-flex justify-content-between">
                <div></div>
                <div class="description-modal-title">DESCRIPTION</div>
                <div class="description-modal-close" @click='close'><i class="fas fa-times"></i></div>
            </div>
            <div class="description-modal-body flex-columns">
                <div><label for="description-text">Description</label></div>
                <div><textarea v-model="description" :class='error ? "error-border" : ""' placeholder="Add a description" name="description-text" id="description-text" class='form-control'></textarea></div>
                <span v-if='error' class='error-text d-flex'>
                    <div class='alert'>!</div>&nbsp;
                    <div>Description is required</div>
                </span>
            </div>
            <div class="description-modal-footer">
                <button class="btn cancel" @click='close'>Cancel</button>
                <button class="btn apply" @click='addDescription'>Apply</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .description-modal {
        position: fixed;
        z-index: 2003;
        width: 100vw;
        height: 100vw;
        top: 0;
        left: 0;
        margin: auto;
        background: rgba(1, 26, 34, 0.301);
    }

    .description-modal-content {
        position: relative;
        left: 50%;
        top: 10%;
        transform: translateX(-50%) translateY(-10%);
        width: 637px;
        height: 395px;
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #00000014;
        border: 1px solid #0BACDB;
        border-radius: 12px;
        opacity: 1;
    }

    .description-modal-header i {
        color: #A3BAC6;
        cursor: pointer;
    }

    .description-modal-header {
        padding: 15px;
        border-bottom: 1px solid #00000014;
    }

    .description-modal-body {
        padding: 30px;
    }

    .description-modal-body label {
        padding-bottom: 10px;
    }

    .description-modal-body textarea {
        resize: none;
        height: 100px;
    }

    .description-modal-footer {
        position: absolute;
        bottom: 20px;
        transform: translateX(190.5px);
    }
    
    .description-modal-footer .btn {
        border-radius: 20px;
        margin: 0 15px;
    }

    .description-modal-footer .cancel {
        background: #21455E;
        color: #fff;
    }
    
    .description-modal-footer .apply {
        background: #0DABD8;
        color: #fff;
    }

    .error-border {
        border-color: red;
    }

    .error-text {
        color: red;
        padding-top: 10px;
    }

    .alert {
        background: #EE5951;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        color: white;
    }
</style>

<script>
export default {
    name: 'EmbedDescriptionModal',
    props: ['video'],
    data: () => ({
        description: null,
        error: false
    }),
    methods: {
        close()
        {
            this.$emit('close', false);
        },
        addDescription()
        {
            if (!this.description) {
                this.error = true;
            } else {
                this.error = false;
                let data = {
                    video_title: this.$props.video.title,
                    video_description: this.description,
                    video_id: this.$props.video.id
                };
                this.axios.post('editor/video/edit-title', data).then(response => {

                    if (response.data.message == 'success') {
                        this.$emit('description', {
                            id: this.video.id,
                            description: this.description
                        });
                        this.close();
                    }
                });
            }
        }
    }
}
</script>