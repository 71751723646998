<template>
    <div class='success-popup'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="281.973" height="60.57" viewBox="0 0 281.973 60.57">
        <defs>
            <filter id="Rectangle_1407" x="0" y="0" width="281.973" height="60.57" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur"/>
            <feFlood flood-opacity="0.039"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <g id="Group_12608" data-name="Group 12608" transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_1407)">
            <g id="Rectangle_1407-2" data-name="Rectangle 1407" transform="translate(9 6)" fill="#fff" stroke="#0ad688" stroke-width="1">
                <rect width="263.973" height="42.57" rx="21" stroke="none"/>
                <rect x="0.5" y="0.5" width="262.973" height="41.57" rx="20.5" fill="none"/>
            </g>
            </g>
            <g id="Group_4358" data-name="Group 4358" transform="translate(2 2)">
            <rect id="Rectangle_1408" data-name="Rectangle 1408" width="36" height="36" rx="18" transform="translate(1 1)" fill="#0ad688"/>
            <g id="Good_checkmark" data-name="Good checkmark" transform="translate(9 11)">
                <g id="Group_4357" data-name="Group 4357">
                <path id="Path_4291" data-name="Path 4291" d="M20.258,68.3a1.028,1.028,0,0,0-1.454,0L6.488,80.614,1.754,75.88A1.028,1.028,0,0,0,.3,77.334l5.461,5.461a1.028,1.028,0,0,0,1.454,0L20.258,69.752A1.028,1.028,0,0,0,20.258,68.3Z" transform="translate(0 -67.997)" fill="#fff" stroke="#0ad688" stroke-width="0.5"/>
                </g>
            </g>
            </g>
            <text id="Anti-Slip_Shoe_Grips" data-name="Anti-Slip Shoe Grips" transform="translate(51.999 19.627)" fill="#21455e" font-size="14" font-family="ArialMT, Arial"><tspan x="0" y="0">{{ title }}</tspan></text>
            <text id="Enter_a_Product_name" data-name="Enter a Product name" transform="translate(52 35)" fill="#21455e" font-size="11" font-family="ArialMT, Arial" opacity="0.7"><tspan x="0" y="0">{{ message }}</tspan></text>
        </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'SuccessPopup',
    props: ['title', 'message']
}
</script>

<style scope>
    .success-popup {
        animation-name: fadeIn;
        animation-duration: 6s;
        animation-iteration-count: 1;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
</style>